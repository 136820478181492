import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewAssignmentOption = ({ option, handleOptionClick }) => {
  return (
    <div key={option.title} className="w-100">
      <div
        role="button"
        tabIndex={0}
        onClick={() => !option.disabled && handleOptionClick(option)}
        onKeyDown={(e) => {
          if (!option.disabled && (e.key === "Enter" || e.key === " ")) {
            handleOptionClick(option);
          }
        }}
        className={`card ia-button-outline d-flex justify-content-center align-items-center ia-shadow p-3 ${
          option.disabled ? "disabled" : ""
        }`}
        style={option.disabled ? { opacity: 0.5, cursor: 'not-allowed', backgroundColor: '#f0f0f0' } : {}}
      
      >
        <div className="d-flex align-items-center w-100">
          {option.isNew && <h4 className="badge bg-success">New!!</h4>}
          {option.isCommingSoon && <h4 className="badge bg-dark">Coming soon!</h4>}
          {option.fontAwesome && (
            <FontAwesomeIcon className="ms-3" size="2x" icon={option.fontAwesome} />
          )}
          <div className="card-body">
            <h3 className="bold">{option.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: option.content }} className="mb-0"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAssignmentOption;
