import { Button } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { PlusCircle } from 'lucide-react'

const AdminNewHearing = () => (
  <NavLink to="/substitute-list">
    <Button
      type="button"
      size="lg"
      color="outline-secondary"
      className="ms-3 border-0 px-2 py-0"
    >
      <PlusCircle size={14} className="align-middle" />
    </Button>
  </NavLink>
)

export default AdminNewHearing
