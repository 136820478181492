import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import SidebarLink from './components/SideBarLink'
import { getActiveLinks } from './utils/sidebarPermissions'
import LogoLandscape from '../../../assets/images/logo-landscape-white.png'
import pigeon from '../../../assets/images/iconwhite.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { toggleSidebar } from '../../../redux/actions/settings'

const Sidebar = ({ isSmallAndOpen }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { activeUser } = useSelector((state) => state.auth)
  const { isOpen } = useSelector((state) => state.settings)

  const [collapsed, setCollapsed] = useState({})

  useEffect(() => {
    let sidebarState
    try {
      sidebarState = JSON.parse(localStorage.getItem('sidebar')) || {}
    } catch (_error) {
      sidebarState = {}
    }
    setCollapsed(sidebarState)
  }, [])

  const toggleCollapse = (id) => {
    setCollapsed((prev) => {
      const newState = { ...prev, [id]: !prev[id] }
      localStorage.setItem('sidebar', JSON.stringify(newState))
      return newState
    })
  }

  const activeLinks = getActiveLinks(activeUser)

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar())
  }
  const handleLogoClick = () => {
    const dashboardPath = '-dashboard'

    if (location.pathname.endsWith(dashboardPath)) {
      handleToggleSidebar()
    } else {
      handleToggleSidebar()
      navigate('/panel')
    }
  }

  return (
    <motion.div
      initial={{ width: isOpen ? 330 : 0 }}
      animate={{ width: isOpen ? (isSmallAndOpen ? '100vw' : 330) : 0 }}
      transition={{ duration: 0.1, ease: 'easeInOut' }}
      className={`custom-sidebar d-flex flex-column sidebar-container ${
        isSmallAndOpen ? 'mobile-expanded' : ''
      }`}
    >
      <div
        onClick={handleLogoClick}
        className="sidebar-brand pt-3 sidebar-logo"
        style={{ height: '74.59px', cursor: 'pointer' }}
      >
        {isOpen ? (
          <img
            className="align-middle mt-1"
            src={LogoLandscape}
            alt="Logo"
            width="160"
          />
        ) : (
          <img
            className="align-middle mt-1"
            src={pigeon}
            alt="Icon"
            width={40}
            height={40}
          />
        )}
      </div>

      <div className="sidebar-links">
        <nav className={`p-2 ${isSmallAndOpen ? 'mobile-expanded' : ''}`}>
          {activeLinks.map(
            ({ label, icon, color, path, children, extraComponent }) => (
              <SidebarLink
                key={label}
                path={path}
                label={label}
                icon={icon}
                color={color}
                children={children}
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
                setCollapsed={setCollapsed}
                isOpen={isOpen}
                extraComponent={extraComponent}
                isSmallAndOpen={isSmallAndOpen}
              />
            )
          )}
        </nav>
      </div>
    </motion.div>
  )
}

export default Sidebar
