import AdminNewHearing from '../components/AdminNewHearing'
import { ENABLE_PIGEON_IN_HOUSE, ENABLE_PIGEONAI } from '../../../../config'
import { ICONS } from '../utils/sidebarIcons'
import { LABELS } from '../utils/sidebarLabels'
import { PATHS } from './sideBarPaths'
import { COLORS } from './sidebarColors'

const conditionalLink = (condition, link) => (condition ? link : null)

const commonLinks = [
  {
    path: PATHS.communityPartners,
    label: LABELS.partners,
    color: COLORS.partners,
    icon: ICONS.globe,
  },
  {
    path: '#',
    label: LABELS.tools,
    icon: ICONS.tool,
    color: COLORS.tools,
    id: 'tools',
    children: [
      { path: PATHS.calculators, icon: ICONS.divide, label: LABELS.calculator },
    ],
  },
]

const commonCompanyPartnerLinks = [
  {
    path: '#',
    label: LABELS.expedients,
    icon: ICONS.folder,
    color: COLORS.expedients,
    id: 'expedients',
    children: [
      { path: PATHS.expedients, icon: ICONS.folder, label: LABELS.views },
      { path: PATHS.procuras, icon: ICONS.procura, label: LABELS.procura },
      { path: PATHS.notaries, icon: ICONS.notary, label: LABELS.notary },
      { path: PATHS.onCalls, icon: ICONS.onCall, label: LABELS.onCall },
      conditionalLink(ENABLE_PIGEON_IN_HOUSE, {
        path: PATHS.pigeonOnBoardList,
        label: LABELS.onBoard,
        icon: ICONS.home,
      }),
    ].filter(Boolean),
  },
]

const pigeonAI = conditionalLink(ENABLE_PIGEONAI, {
  path: PATHS.pigeonAI,
  label: LABELS.pigeonAI,
  color: COLORS.pigeonAI,
  icon: ICONS.pigeonAI,
  id: 'pigeonAI',
})

const pigeonOnBoardLink = conditionalLink(ENABLE_PIGEON_IN_HOUSE, {
  path: PATHS.pigeonOnBoard,
  label: LABELS.onBoard,
  icon: ICONS.home,
  id: 'pigeonOnBoard',
  color: COLORS.pigeonOnBoard,
})

const clientDashboardLink = {
  path: PATHS.clientDashboard,
  label: LABELS.dashboard,
  icon: ICONS.dashboard,
  color: COLORS.dashboard,
  id: 'dashboard',
}

const substituteDashboardLink = {
  path: PATHS.substituteDashboard,
  label: LABELS.dashboard,
  icon: ICONS.dashboard,
  color: COLORS.dashboard,
  id: 'dashboard',
}

const adminLinks = [
  {
    path: PATHS.pigeonAI,
    label: LABELS.pigeonAI,
    icon: ICONS.pigeonAI,
    id: 'pigeonAI',
    color: COLORS.pigeonAI,
  },
  {
    path: PATHS.pigeonOnBoard,
    label: LABELS.onBoard,
    icon: ICONS.home,
    id: 'pigeonOnBoard',
    color: COLORS.pigeonOnBoard,
  },
  {
    path: PATHS.adminDashboard,
    label: LABELS.dashboard,
    icon: ICONS.dashboard,
    id: 'dashboard',
    color: COLORS.dashboard,
  },
  {
    path: '#',
    label: LABELS.hearings,
    icon: ICONS.folder,
    id: 'contratados',
    color: COLORS.expedients,
    children: [
      {
        path: PATHS.expedients,
        label: LABELS.views,
        hasExtra: true,
        extraComponent: <AdminNewHearing />,
        icon: ICONS.folder,
      },
      {
        path: PATHS.notaries,
        icon: ICONS.notary,
        label: LABELS.notary,
      },
      {
        path: PATHS.onCalls,
        label: LABELS.onCall,
        icon: ICONS.phone,
      },
      {
        path: PATHS.procuras,
        label: LABELS.procura,
        icon: ICONS.procura,
      },
      {
        path: PATHS.pigeonOnBoardList,
        label: LABELS.onBoard,
        icon: ICONS.home,
      },
      {
        path: PATHS.drafts,
        label: LABELS.drafts,
        icon: ICONS.drafts,
      },
    ],
  },
  {
    path: PATHS.users,
    label: LABELS.users,
    icon: ICONS.users,
    id: 'users',
    color: COLORS.users,
  },
  {
    path: '#',
    label: LABELS.invoices,
    icon: ICONS.folder,
    id: 'invoices',
    color: COLORS.invoices,
    children: [
      {
        path: PATHS.incomeCompanies,
        label: LABELS.incomeCompany,
        icon: ICONS.incomeCompany,
      },
      {
        path: PATHS.incomeClients,
        label: LABELS.incomeClients,
        icon: ICONS.incomeClients,
      },
      {
        path: PATHS.expenseSubstitutes,
        label: LABELS.expenseSubstitutes,
        icon: ICONS.expenseSubstitutes,
      },
      {
        path: PATHS.refund,
        label: LABELS.refund,
        icon: ICONS.refund,
      },
      {
        path: PATHS.remittances,
        label: LABELS.remittances,
        icon: ICONS.remittances,
      },
    ],
  },
  ...commonLinks,
  {
    path: PATHS.promotions,
    label: LABELS.promotions,
    icon: ICONS.gift,
    color: COLORS.promotions,
  },
  {
    path: PATHS.datasets,
    label: LABELS.dataset,
    icon: ICONS.database,
    color: COLORS.dataset,
  },
  {
    path: PATHS.budget,
    label: LABELS.budget,
    icon: ICONS.dollar,
    color: COLORS.budget,
  },
  {
    path: PATHS.notifications,
    label: LABELS.notifications,
    icon: ICONS.messageSquare,
    color: COLORS.notifications,
  },
].filter(Boolean)

const partnerLinks = [
  pigeonAI,
  clientDashboardLink,
  pigeonOnBoardLink,
  ...commonCompanyPartnerLinks,
  ...commonLinks,
].filter(Boolean)

const companyLinks = [
  pigeonAI,
  clientDashboardLink,
  pigeonOnBoardLink,
  ...commonCompanyPartnerLinks,
  {
    path: PATHS.incomeCompanies,
    label: LABELS.invoices,
    color: COLORS.invoices,
    icon: ICONS.archive,
  },
  ...commonLinks,
].filter(Boolean)

const clientLinks = [
  pigeonAI,
  clientDashboardLink,
  pigeonOnBoardLink,
  {
    path: '#',
    label: LABELS.expedients,
    icon: ICONS.folder,
    color: COLORS.expedients,
    id: 'contratados',
    children: commonCompanyPartnerLinks[0].children,
  },
  {
    path: PATHS.incomeClients,
    label: LABELS.invoices,
    id: 'invoices',
    color: COLORS.invoices,
    icon: ICONS.archive,
  },
  ...commonLinks,
].filter(Boolean)

const substituteLinks = [
  pigeonAI,
  substituteDashboardLink,
  pigeonOnBoardLink,
  {
    path: '#',
    label: LABELS.assigned,
    icon: ICONS.inbox,
    color: COLORS.assigned,
    id: 'asignados',
    children: [
      {
        path: PATHS.assignedExpedients,
        icon: ICONS.folder,
        label: LABELS.views,
      },
      {
        path: PATHS.assignedNotaries,
        icon: ICONS.notary,
        label: LABELS.notary,
      },
      { path: PATHS.assignedOnCalls, icon: ICONS.onCall, label: LABELS.onCall },
      {
        path: PATHS.assignedPigeonOnBoards,
        label: LABELS.onBoard,
        icon: ICONS.home,
      },
    ].filter(Boolean),
  },
  {
    path: '#',
    label: LABELS.hired,
    icon: ICONS.inbox,
    color: COLORS.expedients,
    id: 'contratados',
    children: [
      {
        path: PATHS.expedients,
        icon: ICONS.folder,
        label: LABELS.views,
      },
      {
        path: PATHS.procuras,
        icon: ICONS.procura,
        label: LABELS.procura,
      },
      { path: PATHS.notaries, icon: ICONS.notary, label: LABELS.notary },
      { path: PATHS.onCalls, icon: ICONS.onCall, label: LABELS.onCall },
    ].filter(Boolean),
  },
  {
    path: '#',
    label: LABELS.invoices,
    color: COLORS.invoices,
    icon: ICONS.folder,
    id: 'facturas',
    children: [
      {
        path: PATHS.incomeClients,
        icon: ICONS.incomeClients,
        label: LABELS.hearingInvoices,
      },
      {
        path: PATHS.expenseSubstitutes,
        icon: ICONS.expenseSubstitutes,
        label: LABELS.substitutionInvoices,
      },
    ],
  },
  ...commonLinks,
].filter(Boolean)

const procuradorLinks = [
  pigeonAI,
  pigeonOnBoardLink,
  substituteDashboardLink,
  {
    path: '#',
    label: LABELS.assigned,
    icon: ICONS.inbox,
    color: COLORS.assigned,
    id: 'asignados',
    children: [
      {
        path: PATHS.assignedProcuras,
        icon: ICONS.procura,
        label: LABELS.procura,
      },
      conditionalLink(ENABLE_PIGEON_IN_HOUSE, {
        path: PATHS.assignedPigeonOnBoards,
        label: LABELS.onBoard,
        icon: ICONS.home,
      }),
    ].filter(Boolean),
  },
  {
    path: '#',
    label: LABELS.hired,
    icon: ICONS.inbox,
    color: COLORS.hired,
    id: 'contratados',
    children: [
      {
        path: PATHS.expedients,
        icon: ICONS.inbox,
        label: LABELS.views,
      },
      {
        path: PATHS.procuras,
        icon: ICONS.procura,
        label: LABELS.procura,
      },
      {
        path: PATHS.onCalls,
        icon: ICONS.onCall,
        label: LABELS.onCall,
      },
    ].filter(Boolean),
  },
  {
    path: '#',
    label: LABELS.invoices,
    icon: ICONS.folder,
    color: COLORS.invoices,
    id: 'facturas',
    children: [
      {
        path: PATHS.incomeClients,
        icon: ICONS.incomeClients,
        label: LABELS.hearingInvoices,
      },
      {
        path: PATHS.expenseSubstitutes,
        icon: ICONS.expenseSubstitutes,
        label: LABELS.substitutionInvoices,
      },
    ],
  },
  ...commonLinks,
].filter(Boolean)

export const getRoleLinks = (activeUser) => ({
  admin: adminLinks,
  client: activeUser.is_substitute
    ? substituteLinks
    : activeUser.is_procurador
    ? procuradorLinks
    : clientLinks,
  company: companyLinks,
  partner: partnerLinks,
})
