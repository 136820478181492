import * as types from '../constants'

const initialState = {
  admin: {},
  client: {
    tableData: [
      { label: 'Expedientes', value: 25 },
      { label: 'Notarías', value: 25 },
      { label: 'Procuras', value: 25 },
      { label: 'OnCall', value: 25 },
    ],
    workedWith: 0,
    judicialParties: 0,
    totalOrders: 0,
    isSubstituteData: false,
    completedOrders: 0,
  },
  substitute: {
    tableData: [
      { label: 'Expedientes', finished: 25 },
      { label: 'Notarías', finished: 25 },
      { label: 'Procuras', finished: 25 },
      { label: 'OnCall', finished: 25 },
    ],
    totalJobsDone: 0,
    totalJobsInProgress: 0,
    totalJobs: 0,
    issuesCount: 0,
    workedWith: 0,
  },
  loading: false,
}

export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ADMIN_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case types.LOAD_ADMIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        admin: {
          ...action.payload,
        },
        loading: false,
      }
    case types.LOAD_CLIENT_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case types.LOAD_CLIENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          clientTableData: action.payload.tableData || state.client.tableData,
          workedWith: action.payload.workedWith || state.client.workedWith,
          judicialParties:
            action.payload.judicialParties || state.client.judicialParties,
          totalOrders: action.payload.totalOrders || state.client.totalOrders,
          clientCalendar: action.payload.calendar,
          isSubstituteData: action.payload.isSubstituteData || false,
          clientUsername: action.payload.username || '',
          completedOrders:
            action.payload.completedOrders || state.client.completedOrders,
        },
        loading: false,
      }
    case types.LOAD_SUBSTITUTE_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case types.LOAD_SUBSTITUTE_DASHBOARD_SUCCESS:
      return {
        ...state,
        substitute: {
          ...state.substitute,
          totalJobsDone:
            action.payload.totalJobsDone || state.substitute.totalJobsDone,
          totalJobsInProgress:
            action.payload.totalJobsInProgress ||
            state.substitute.totalJobsInProgress,
          totalJobs: action.payload.totalJobs || state.substitute.totalJobs,
          substituteTableData:
            action.payload.tableData || state.client.tableData,
          substituteCalendar: action.payload.calendar,
          issuesCount:
            action.payload.issuesCount || state.substitute.issuesCount,
          substituteUsername: action.payload.username || '',
          workedWith: action.payload.workedWith || state.substitute.workedWith,
        },
        loading: false,
      }
    default:
      return state
  }
}
