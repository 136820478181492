// import { ON_BOARD_CLIENTS } from '../../config'

export const temporalAllowOnBoard = (activeUser) => {
  // const allowedIds = ON_BOARD_CLIENTS?.split(',') || []

  return [
    {
      path: '/panel/new-pigeon-onboard/',
      component: 'CreateOnBoardPage',
      exact: true,
      allowed: !(activeUser.is_substitute || activeUser.is_procurador),
    },
    {
      path: '/panel/pigeon-onboard/',
      component: 'ListOnBoardPage',
      exact: true,
      allowed: true,
    },
    {
      path: '/panel/pigeon-onboard/:project_id',
      component: 'DetailOnBoardPage',
      exact: true,
      allowed: true,
    },
  ].filter((route) => route.allowed)
}
