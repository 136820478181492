import React, { useState } from 'react'
import ButtonNewAssigment from './components/button'
import { Modal } from 'reactstrap'
import CreateNewAssignment from './components/createNewAssignment'
import { Button } from 'reactstrap'

const CreateNewAssignmentModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSecondLevel, setIsSecondLevel] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)  
  
  const handleOnClickBack = () => {
    setIsSecondLevel(false)
    setSelectedOption(null)
  }

  return (
    <div>
      <ButtonNewAssigment open={() => setShowModal(true)}>
        Abrir Modal
      </ButtonNewAssigment>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        className="ia-shadow"
        style={{
          borderRadius: '20px',
          overflow: 'hidden',
        }}
      >
        <div className="rounded position-relative" style={{ backgroundColor: '#fff' }} >
        {isSecondLevel && (
            <Button
              className="ia-button position-absolute top-0 start-0 m-3"
              onClick={handleOnClickBack}
            >
              Atrás
            </Button>
          )}
          <Button
            type="button"
            color="outline-danger"
            className="ia-button position-absolute top-0 end-0 m-3"
            onClick={() => setShowModal(false)}
          >
            ✕
          </Button>
          <div className="d-flex justify-content-center align-items-center p-3" style={{ minHeight: '60px' }}>
            {isSecondLevel ? (
              <div style={{ height: '24px' }}></div> // Placeholder to maintain height
            ) : (
              <h2 className="mb-0 ia-color-light p-1 bold">
                ¿En qué te podemos ayudar?
              </h2>
            )}
          </div>
          <CreateNewAssignment
            setIsSecondLevel={setIsSecondLevel}
            closeModal={() => setShowModal(false)}
            isSecondLevel={isSecondLevel}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
      </Modal>
    </div>
  )
}

export default CreateNewAssignmentModal
