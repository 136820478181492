import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logoImg from '../../../assets/images/2.png'
import { options } from './productsOptions'
import NewAssignmentOption from '../components/NewAssignmentOptions'

const CreateNewAssignment = ({
  closeModal,
  setIsSecondLevel,
  selectedOption,
  setSelectedOption,
}) => {
  const navigate = useNavigate()
  const { activeUser } = useSelector((state) => state.auth)

  const handleOptionClick = (option) => {
    if (option.suboptions) {
      setSelectedOption(option)
      setIsSecondLevel(true)
    } else {
      closeModal()
      setTimeout(() => {
        navigate(option.to)
      }, 100)
    }
  }

  options.forEach((option) => {
    option.suboptions.forEach((suboption) => {
      if (suboption.title === 'Pigeon On Board') {
        if (!(activeUser.is_substitute || activeUser.is_procurador)) {
          suboption.show = true
          suboption.disabled = false
          suboption.isNew = true
          suboption.isCommingSoon = false
        } else {
          suboption.show = true
          suboption.disabled = true
          suboption.isNew = false
          suboption.isCommingSoon = true
        }
      }
    })
  })

  return (
    <div className="d-flex flex-column flex-md-row align-items-center bg-white p-4 rounded ia-shadow">
      <div className="d-flex flex-column w-100 w-md-50">
        {selectedOption ? (
          <>
            {selectedOption.suboptions.map((suboption) => (
              <div
                key={suboption.title}
                role="button"
                tabIndex={0}
                onClick={() =>
                  !suboption.disabled && handleOptionClick(suboption)
                }
                onKeyDown={(e) => {
                  if (
                    !suboption.disabled &&
                    (e.key === 'Enter' || e.key === ' ')
                  ) {
                    handleOptionClick(suboption)
                  }
                }}
                className={`card ia-button-outline d-flex justify-content-center align-items-center ia-shadow p-3 ${
                  suboption.disabled ? 'disabled' : ''
                }`}
                style={
                  suboption.disabled
                    ? {
                        opacity: 0.5,
                        cursor: 'not-allowed',
                        backgroundColor: '#f0f0f0',
                      }
                    : {}
                }
                aria-disabled={suboption.disabled}
              >
                <div className="d-flex align-items-center w-100">
                  {suboption.isNew && (
                    <h4 className="badge bg-success">New!!</h4>
                  )}
                  {suboption.isCommingSoon && (
                    <h4 className="badge bg-dark">Coming soon!</h4>
                  )}
                  {suboption.fontAwesome && (
                    <FontAwesomeIcon
                      className="ms-3"
                      size="2x"
                      icon={suboption.fontAwesome}
                    />
                  )}
                  <div className="card-body">
                    <h3 className="bold">{suboption.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: suboption.content }}
                      className="mb-0"
                    ></p>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          options.map((option) => (
            <NewAssignmentOption
              option={option}
              handleOptionClick={handleOptionClick}
              key={option.title}
            />
          ))
        )}
      </div>

      <div className="d-none d-md-flex align-items-center justify-content-center w-50">
        {!selectedOption && (
          <img
            src={logoImg}
            alt="Logo"
            className="img-fluid"
            style={{ maxHeight: '660px' }}
          />
        )}
        {selectedOption?.imgSrc && (
          <img
            src={selectedOption.imgSrc}
            alt={selectedOption.title}
            className="img-fluid"
            style={{ maxHeight: '414px' }}
          />
        )}
      </div>
    </div>
  )
}

export default CreateNewAssignment
