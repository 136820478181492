export const PATHS = {
  adminDashboard: '/panel/admin-dashboard',
  clientDashboard: '/panel/client-dashboard',
  budget: '/panel/budget',
  substituteDashboard: '/panel/substitute-dashboard',
  pigeonAI: '/panel/pigeon-ai',
  pigeonOnBoard: '/panel/pigeon-onboard?status=awaiting_assignation&show=all',
  pigeonOnBoardList: '/panel/pigeon-onboard',
  users: '/panel/users',
  expedients: '/panel/expedients',
  notaries: '/panel/expedients?expedient_type=notary',
  onCalls: '/panel/projects-short-term',
  procuras: '/panel/procura',
  drafts: '/panel/drafts',
  judicialBriefs: '/panel/judicial-briefs',
  invoices: '/panel/invoices',
  incomeCompanies: '/panel/invoices?type=income_company',
  incomeClients: '/panel/invoices?type=income',
  expenseSubstitutes: '/panel/invoices?type=expense',
  refund: '/panel/invoices?type=refund',
  remittances: '/panel/remittances',
  calculators: '/panel/calculators',
  promotions: '/panel/promotions',
  datasets: '/panel/dataset',
  notifications: '/panel/user-reminders',
  communityPartners: '/community',
  assignedExpedients: '/panel/assigned-expedients',
  assignedNotaries: '/panel/assigned-expedients?expedient_type=notary',
  assignedOnCalls: '/panel/assigned-projects-short-term',
  assignedProcuras: '/panel/assigned-procuras',
  assignedPigeonOnBoards: '/panel/assigned-pigeon-onboard',
}
