import * as types from '../constants'
import { toast } from 'react-toastify'

const initialState = {
  userContracts: [],
  loading: false,
  error: null,
}

export const userContracts = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USER_CONTRACTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case types.LOAD_USER_CONTRACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userContracts: action.payload.userContracts,
      }

    case types.LOAD_USER_CONTRACTS_ERROR:
      toast.error('Error al cargar contratos de usuario', {
        theme: 'colored',
      })
      return {
        ...state,
        loading: false,
      }

    case types.CREATE_USER_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case types.CREATE_USER_CONTRACT_SUCCESS: {
      return {
        ...state,
        userContracts: [...state.userContracts, action.payload.userContract],
        loading: false,
      }
    }

    case types.CREATE_USER_CONTRACT_ERROR:
      toast.error('Error al crear contrato de usuario', {
        theme: 'colored',
      })
      return { ...state, loading: false }

    case types.DOWNLOAD_USER_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.DOWNLOAD_USER_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        hasDownloaded: true,
      }

    case types.DOWNLOAD_USER_CONTRACT_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return { ...state, loading: false }
  }
}
