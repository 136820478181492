import {
  LOAD_BUDGET_REQUEST,
  LOAD_BUDGET_SUCCESS,
  LOAD_BUDGET_ERROR,
  LOAD_BUDGET_RECORDS_REQUEST,
  LOAD_BUDGET_RECORDS_SUCCESS,
  LOAD_BUDGET_RECORDS_ERROR,
  UPDATE_BUDGET_RECORD_REQUEST,
  UPDATE_BUDGET_RECORD_SUCCESS,
  UPDATE_BUDGET_RECORD_ERROR,
  SET_BUDGET_FILTERS,
  SET_BUDGET_SORT,
  SET_BUDGET_PAGINATION,
} from '../constants'

const initialState = {
  loading: false,
  error: null,
  period: {
    name: '',
    quarter: 'Q1',
    year: new Date().getFullYear(),
    startDate: '',
    endDate: '',
  },
  budget: {
    total: 0,
    used: 0,
    remaining: 0,
    percentageUsed: 0,
  },
  statistics: {
    totalRecords: 0,
    byStatus: {},
    topSubstitutes: [],
    topAdmins: [],
    budgetHistory: [],
  },
  records: {
    data: [],
    pagination: {
      page: 1,
      limit: 10,
      total: 0,
    },
    filters: {
      year: new Date().getFullYear(),
      quarter: 'Q1',
      showReasigned: false,
      status: [],
      documentType: [],
      dateRange: null,
    },
    sort: {
      field: 'created_at',
      direction: 'desc',
    },
  },
}

const budgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUDGET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOAD_BUDGET_SUCCESS:
      const budget = {
        total: Number(action.payload.budget.total) || 0,
        used: Number(action.payload.budget.used) || 0,
        remaining: Number(action.payload.budget.remaining) || 0,
        percentageUsed: Number(action.payload.budget.percentageUsed) || 0,
      }

      return {
        ...state,
        loading: false,
        period: action.payload.period,
        budget: budget,
        statistics: action.payload.statistics,
      }
    case LOAD_BUDGET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case LOAD_BUDGET_RECORDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOAD_BUDGET_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: {
          ...state.records,
          data: action.payload.data,
          pagination: {
            ...state.records.pagination,
            total: action.payload.total,
          },
        },
      }
    case LOAD_BUDGET_RECORDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_BUDGET_RECORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case UPDATE_BUDGET_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        records: {
          ...state.records,
          data: state.records.data.map((record) =>
            record._id === action.payload._id ? action.payload : record
          ),
        },
      }
    case UPDATE_BUDGET_RECORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case SET_BUDGET_FILTERS:
      return {
        ...state,
        records: {
          ...state.records,
          filters: {
            ...state.records.filters,
            ...action.payload,
          },
          pagination: {
            ...state.records.pagination,
            page: 1, // Reset to first page when filters change
          },
        },
      }
    case SET_BUDGET_SORT:
      return {
        ...state,
        records: {
          ...state.records,
          sort: action.payload,
        },
      }
    case SET_BUDGET_PAGINATION:
      return {
        ...state,
        records: {
          ...state.records,
          pagination: {
            ...state.records.pagination,
            ...action.payload,
          },
        },
      }
    default:
      return state
  }
}

export default budgetReducer
