import * as types from '../constants'

const initialState = {
  docs: [],
  user: { substitutes_excluded: [], substitutes_favorites: [] },
  substitutes: [],
  partners: [],
  loading: false,
}

export const users = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        tableData: action.payload.tableData,
        issuesCount: action.payload.issuesCount,
        totalJobs: action.payload.totalJobs,
        partners: action.payload.partners || [],
        limits: action.payload.limits,
        responseTimeStats: action.payload.responseTimeStats,
      }

    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        partners: action.payload.partners || [],
      }

    case types.UPDATE_USER_ERROR:
      return state

    case types.LOAD_SUBTITUTES_SUCCESS:
      return {
        ...state,
        substitutes: action.payload,
      }

    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        user: {},
      }

    case types.DELETE_USER_ERROR:
      return {
        ...state,
      }
    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        docs: [action.payload.user, ...state.docs],
        partners: [action.payload.user, ...state.partners],
        loading: false,
      }

    case types.CREATE_USER_ERROR:
      return { ...state, loading: false }

    case types.ADD_SUBSTITUTE_EXCLUDED_ERROR:
      return state

    case types.ADD_SUBSTITUTE_EXCLUDED_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_excluded: [
            ...state.user.substitutes_excluded,
            action.payload.substitute_excluded,
          ],
        },
      }

    case types.REMOVE_SUBSTITUTE_EXCLUDED_ERROR:
      return state

    case types.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_excluded: [...action.payload.user?.substitutes_excluded],
        },
      }

    case types.ADD_FAVORITE_SUBSTITUTE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_favorites: [
            ...state.user.substitutes_favorites,
            action.payload.substitute_favorite,
          ],
        },
      }

    case types.REMOVE_FAVORITE_SUBSTITUTE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_favorites: [
            ...action.payload.user?.substitutes_favorites,
          ],
        },
      }

    case types.SET_LINKED_PARTNERS_SUCCESS:
    case types.DELETE_LINKED_PARTNER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          linked_partners: [...action.payload.partner.linked_partners],
        },
      }

    case types.SET_LINKED_PARTNERS_ERROR:
      return state

    case types.DELETE_LINKED_PARTNER_ERROR:
      return state

    case types.ADD_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          custom_fields: action.payload.custom_fields,
        },
      }

    case types.UPDATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          custom_fields: action.payload.custom_fields,
        },
      }

    case types.ADD_CUSTOM_FIELD_ERROR:
      return {
        ...state,
      }

    case types.UPDATE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
      }

    case types.CHANGE_PARTNER_TO_COMPANY_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      }

    case types.CHANGE_PARTNER_TO_COMPANY_ERROR:
      return {
        ...state,
      }

    case types.ADD_AUTH_POOL_SUCCESS:
    case types.EDIT_AUTH_POOL_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      }

    case types.ADD_AUTH_POOL_ERROR:
    case types.EDIT_AUTH_POOL_ERROR:
      return {
        ...state,
      }

    default:
      return state
  }
}
