import * as types from '../constants'

const defaultState = {
  files: [],
  downloadReady: false,
  loading: false,
  hasDownloaded: false,
  instructa: {},
  instructas: [],
}

export const pigeonAI = (state = defaultState, action) => {
  switch (action.type) {
    case types.PIGEONAI_UPLOAD_FILES_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.PIGEONAI_UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        instructa: action.payload.instructa,
        instructas: [action.payload.instructa, ...state.instructas],
      }

    case types.PIGEONAI_UPLOAD_FILES_ERROR:
      return {
        ...state,
        loading: false,
        ...defaultState,
      }

    case types.PIGEONAI_DOWNLOAD_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.PIGEONAI_DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case types.PIGEONAI_DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        loading: false,
      }

    case types.PIGEONAI_GET_STATUS_REQUEST:
      return {
        ...state,
      }

    case types.PIGEONAI_GET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadReady: action.payload.downloadReady,
        instructa: action.payload?.instructa,
        instructas: state.instructas.map((instructa) => {
          if (instructa._id === action.payload.instructa._id) {
            return action.payload.instructa
          }
          return instructa
        }),
      }

    case types.PIGEONAI_GET_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        instructa: action.payload?.instructa,
        instructas: state.instructas.map((instructa) => {
          if (instructa._id === action.payload.instructa._id) {
            return action.payload.instructa
          }
          return instructa
        }),
      }

    case types.RESET_DOWNLOAD_READY:
      return {
        ...state,
        downloadReady: false,
        hasDownloaded: false,
        instructa: {},
      }

    case types.PIGEONAI_LOAD_INSTRUCTAS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.PIGEONAI_LOAD_INSTRUCTAS_SUCCESS:
      return {
        ...state,
        loading: false,
        instructas: [...action.payload.docs],
        totalPages: action.payload.totalPages,
        page: action.payload.page,
      }

    case types.PIGEONAI_LOAD_INSTRUCTAS_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
