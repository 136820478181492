import { NavLink } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toggleSidebar } from '../../../../redux/actions/settings'
import { useEffect } from 'react'
import ProjectLTCounter from '../../ProjectLTCounter'

const SidebarLink = ({
  path,
  label,
  icon: Icon,
  children,
  toggleCollapse,
  collapsed,
  extraComponent,
  isOpen,
  color,
  setCollapsed,
  isSmallAndOpen,
}) => {
  const dispatch = useDispatch()
  const isCollapsed = collapsed?.[label] || false

  useEffect(() => {
    if (!isOpen) {
      setCollapsed({})
    }
  }, [isOpen, setCollapsed])

  const handleClickMenu = () => {
    if (!isOpen) {
      dispatch(toggleSidebar(true))
    } else if (children) {
      toggleCollapse(label)
    } else if (isSmallAndOpen) {
      dispatch(toggleSidebar(false))
    }
  }

  const handleClickSubmenuItem = () => {
    if (isSmallAndOpen && isOpen) {
      dispatch(toggleSidebar(false))
    }
  }
  return (
    <>
      {!children ? (
        <motion.div
          whileHover={{ scale: 1.02, x: isOpen ? 5 : 0 }}
          whileTap={{ scale: 0.98 }}
          className="d-flex align-items-center justify-content-between"
        >
          <NavLink
            onClick={handleClickMenu}
            to={path}
            className="sidebar-link d-flex align-items-center justify-content-start p-3 rounded w-100"
            style={{ backgroundColor: '#111827', height: '53px' }}
            title={!isOpen ? label : ''}
          >
            {Icon && <Icon color={color} size={18} className="align-middle" />}
            {isOpen && <span className="ms-3 text-white">{label}</span>}
            {label === 'Pigeon On Board' && <ProjectLTCounter />}
          </NavLink>
          {extraComponent && <div className="ms-2">{extraComponent}</div>}
        </motion.div>
      ) : (
        <motion.div
          onClick={handleClickMenu}
          whileHover={{ scale: 1.02, x: isOpen ? 5 : 0 }}
          whileTap={{ scale: 0.98 }}
          className={`sidebar-link d-flex align-items-center justify-content-start p-3 rounded cursor-pointer ${
            isCollapsed ? '' : 'collapsed'
          }`}
          aria-expanded={isCollapsed}
          title={!isOpen ? label : ''}
        >
          {Icon && <Icon size={18} color={color} className="align-middle" />}
          {
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className="ms-3 text-white">{label}</span>

              {!isCollapsed ? (
                <FaChevronDown
                  size={18}
                  className="align-middle me-3 arrow-icon"
                />
              ) : (
                <FaChevronUp
                  size={18}
                  className="align-middle me-3 arrow-icon"
                />
              )}
            </div>
          }
        </motion.div>
      )}

      <AnimatePresence>
        {children && isCollapsed && (
          <motion.div
            key={label}
            initial={{ scaleY: 0, opacity: 0 }}
            animate={{ scaleY: 1, opacity: 1 }}
            exit={{ scaleY: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="submenu ps-4 mt-1"
            style={{ overflow: 'hidden', transformOrigin: 'top' }}
          >
            <motion.ul
              className="sidebar-dropdown list-unstyled"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                hidden: { opacity: 0, x: -10 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    staggerChildren: 0.1,
                  },
                },
              }}
            >
              {children?.map((child) => (
                <motion.li
                  key={child.label}
                  variants={{
                    hidden: { opacity: 0, x: -20 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  transition={{ duration: 0.3, ease: 'easeOut' }}
                >
                  <NavLink
                    onClick={handleClickSubmenuItem}
                    to={child.path}
                    className="sidebar-link sidebar-submenu-item text-white d-flex align-items-center"
                  >
                    {child.icon && (
                      <child.icon size={18} className="me-3 text-light" />
                    )}
                    <span className="ms-2 text-white">{child.label}</span>
                    {child.extraComponent && child.extraComponent}
                  </NavLink>
                </motion.li>
              ))}
            </motion.ul>
            {extraComponent && <div className="ms-2">{extraComponent}</div>}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default SidebarLink
