import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  invoices: {},
  invoice: {},
  loading: false,
}

export const invoice = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
      }

    case types.GET_INVOICES_ERROR:
      toast.error(
        'Ha ocurrido un error, cargando tus facturas si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return {
        ...state,
        invoices: {},
        loading: false,
      }

    case types.GET_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.payload.invoice,
        loading: false,
      }

    case types.GET_INVOICE_ERROR:
      toast.error(
        'Ha ocurrido un error, cargando tu factura si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return {
        ...state,
        loading: false,
      }

    case types.UPDATE_INVOICE_SUCCESS:
      toast.success('Factura actualizada', { theme: 'colored' })
      return {
        ...state,
        invoice: action.payload.invoice,
      }

    case types.UPDATE_INVOICE_ERROR:
      toast.error(
        'ERROR la factura no ha sido actualizada correctamente contacta con el administrador',
        { theme: 'colored' }
      )
      return state

    case types.DELETE_INVOICE_SUCCESS:
      toast.success('Se ha eliminado la factura', { theme: 'colored' })
      return state

    case types.DELETE_INVOICE_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return state
    default:
      return state
  }
}
