//prettier-ignore
export const hearingDefaultList = [
  { id: 0, value: 'Acto de conciliación', type: 'hearing' }, //not visible for procura
  { id: 1, value: 'Asistencia en comisaria', type: 'hearing' }, //not visible for procura
  { id: 2, value: 'Audiencia previa', type: 'hearing' },
  { id: 3, value: 'Declaraciones en fase de ejecución por delitos leves',  type: 'hearing'},
  { id: 4, value: 'Juicio ordinario', type: 'hearing' },
  { id: 5, value: 'Juicio rapido', type: 'hearing' },
  { id: 6, value: 'Juicio verbal', type: 'hearing' },
  { id: 7, value: 'Oposición ejecución hipotecaria', type: 'hearing' },
  { id: 8, value: 'Oposición ejecución ordinaria', type: 'hearing' },
  { id: 9, value: 'Vista de desahucio', type: 'hearing' },
  { id: 10, value: 'Vista de medidas cautelares (civil)',type: 'hearing'}, 
  { id: 11, value: 'Vista de ocupantes ejecución hipotecaria', type: 'hearing'},
  { id: 12, value: 'Vista diligencias preliminares', type: 'hearing' },
  { id: 13, value: 'Vista incidente Art. 712 LEC', type: 'hearing' },
  { id: 14, value: 'Acompañamiento en notaría', type: 'notary' }, 
  { id: 15, value: 'Firma en notaría con apoderado', type: 'notary', powers: true },
  // { id: 17, value: 'Informe posesorio', type: 'possession' },
  { id: 20, value: 'Recogida de copias', type: 'procura' },
  { id: 21, value: 'Lanzamientos', type: 'procura', price:70, cost_price:50 },
  { id: 22, value: 'Mandamiento de pago', type: 'procura' },
  { id: 23, value: 'Requerimiento de pago', type:'procura'},
  { id: 24, value: 'Emplazamientos', type: 'procura' },
  { id: 99, value: 'Otros', type: 'hearing' },
]

export const notaryHearingTypesIds = hearingDefaultList
  .filter((ht) => ht.type === 'notary')
  .map((ht) => String(ht.id))

export const possesionsHearingTypesIds = hearingDefaultList
  .filter((ht) => ht.type === 'possession')
  .map((ht) => String(ht.id))

export const powersHearingTypesIds = hearingDefaultList
  .filter((ht) => !!ht.powers)
  .map((ht) => String(ht.id))

export const hearingTypeList = hearingDefaultList.filter(
  (ht) => ht.type !== 'procura' && ht.type !== 'possession'
)

export const hearingTypeListForForm = hearingDefaultList.filter(
  (ht) => ht.type === 'hearing' && ht.type !== 'notary'
)

const filteredProcuraIds = [0, 1]

export const hearingProcuraList = hearingDefaultList.filter(
  (ht) =>
    ht.type !== 'notary' &&
    ht.type !== 'possession' &&
    !filteredProcuraIds.includes(ht.id)
)
