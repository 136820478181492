import { getRoleLinks } from './sidebarLinks'

export const getActiveLinks = (activeUser) => {
  const { role } = activeUser
  const roleLinks = getRoleLinks(activeUser)

  if (!roleLinks[role]) return []

  return roleLinks[role]
}
