import * as types from '../constants'

const defaultState = {
  projectCandidates: [],
  projectCandidate: {},
  assigned: {},
  loading: false,
}

export const projectCandidate = (state = defaultState, action) => {
  switch (action.type) {
    case types.UPDATE_PROJECT_CANDIDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.UPDATE_PROJECT_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        projectCandidates: [
          ...state.projectCandidates.map((projectCandidate) => {
            if (projectCandidate._id === action.payload.projectCandidate._id) {
              return action.payload.projectCandidate
            }
            return projectCandidate
          }),
        ],
      }

    case types.LOAD_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        projectCandidates: action.payload.projectlt.candidates,
      }

    case types.DECLINE_PROJECT_LONG_TERM_CANDIDATE_SUCCESS:
      return {
        ...state,
        projectCandidates: action.payload.candidates,
        loading: false,
      }

    case types.DELETE_PROJECT_CANDIDATE_SUCCESS:
      return {
        ...state,
        projectCandidates: state.projectCandidates.filter(
          (projectCandidate) =>
            projectCandidate._id !== action.payload.deletedId
        ),
        loading: false,
      }

    case types.UPDATE_USER_FEEDBACK_SCORE_SUCCESS:
      return {
        ...state,
        projectCandidates: state.projectCandidates.map((projectCandidate) => {
          if (projectCandidate.substitute_id._id === action.payload.user._id) {
            return {
              ...projectCandidate,
              substitute_id: action.payload.user,
            }
          }
          return projectCandidate
        }),
        loading: false,
      }

    case types.APPLY_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        projectCandidates: action.payload.projectlt.candidates,
        loading: false,
      }

    default:
      return state
  }
}
