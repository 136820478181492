export const COLORS = {
  pigeonAI: '#06b6d4', // text-cyan-500 → rgb(6, 182, 212)
  pigeonOnBoard: '#3b82f6', // text-blue-500 → rgb(59, 130, 246)
  dashboard: '#6366f1', // text-indigo-500 → rgb(99, 102, 241)
  expedients: '#a855f7', // text-purple-500 → rgb(168, 85, 247)
  judicialBriefs: '#8b5cf6', // text-violet-500 → rgb(139, 92, 246)
  users: '#ec4899', // text-pink-500 → rgb(236, 72, 153)
  invoices: '#f59e0b', // text-amber-500 → rgb(245, 158, 11)
  tools: '#10b981', // text-green-500 → rgb(16, 185, 129)
  partners: '#2563eb', // text-blue-600 → rgb(37, 99, 235)
  promotions: '#e11d48', // text-rose-600 → rgb(225, 29, 72)
  dataset: '#db2777', // text-pink-600 → rgb(219, 39, 119)
  notifications: '#ca8a04', // text-yellow-600 → rgb(202, 138, 4)
  hired: '#f87171', // text-rose-500 → rgb(248, 113, 113)
  assigned: '#34d399', // text-green-500 → rgb(52, 211, 153)
  budget: '#f59e0b', // text-amber-500 → rgb(245, 158, 11)
}
