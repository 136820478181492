import React from 'react'
import moment from 'moment'
import {
  HelpCircle,
  Clock,
  Check,
  ArrowRight,
  XCircle,
  Lock,
} from 'react-feather'
import { toast } from 'react-toastify'
import { baseAPI } from '../../src/config'
import { project_st_type } from './substituteSkills'
import { judicialParties } from './judicialParties'
import { hearingDefaultList } from './hearingType'
import { loadDrafts } from '../redux/actions/expedient'
import { loadProcuraDrafts } from '../redux/actions/procura'
import { loadOnCallDrafts } from '../redux/actions/projectShortTerm'
// import { loadInHouseDrafts } from '../redux/actions/inHouse'
import { ONBOARD_BASE_PRICE } from '../config'

export const isHearingUrgent = (date, isProcura = false) => {
  const days = 1
  const time = isProcura ? 8 : 15

  return (
    (moment(date).format('DD/MM/YYYY') ===
      moment().add(days, 'days').format('DD/MM/YYYY') &&
      moment().format('HH') * 1 >= time) ||
    (['lunes', 'Monday'].includes(moment(date).format('dddd')) &&
      ['viernes', 'Friday', 'sábado', 'Saturday', 'domingo', 'Sunday'].includes(
        moment().format('dddd')
      ) &&
      moment(date).isSame(moment().startOf('isoWeek').add(1, 'weeks'), 'day') &&
      moment().format('HH') * 1 >= time)
  )
}

export const expedientStatusName = (status) => {
  let name = ''

  switch (status) {
    // expedient statuses
    case 'awaiting_acceptation':
      name = 'Pendiente de aceptación'
      break
    case 'awaiting_assignation':
      name = 'Pendiente de asignación'
      break
    case 'assigned':
      name = 'Asignado'
      break
    case 'project_review':
      name = 'Revisando documentación'
      break
    case 'awaiting_documentation':
      name = 'Esperando documentación'
      break
    case 'in_progress':
      name = 'En proceso'
      break
    case 'delivered':
      name = 'Entregado'
      break
    case 'to_review':
      name = 'En revisión'
      break
    case 'reviewed':
      name = 'Revisado'
      break
    case 'celebration_pending':
      name = 'Pendiente de celebración'
      break
    case 'celebrated':
      name = 'Celebrada'
      break
    case 'posponed':
      name = 'Pospuesto'
      break
    case 'suspended':
      name = 'Suspendida'
      break
    case 'canceled':
      name = 'Cancelado'
      break
    case 'closed':
      name = 'Finalizado'
      break
    case 'draft':
      name = 'Borrador'
      break

    // invoice statuses 'proforma', 'creada', 'pagada, cobrada'
    case 'proforma':
      name = 'Proforma'
      break
    case 'creada':
      name = 'Creada'
      break
    case 'pagada':
      name = 'Pagada'
      break
    case 'cobrada':
      name = 'cobrada'
      break
    default:
      break
  }

  return name
}

export const invoiceStatusName = (status) => {
  let name = ''

  switch (status) {
    // invoice statuses 'proforma', 'creada', 'pagada, cobrada'
    case 'proforma':
      name = 'Proforma'
      break
    case 'creada':
      name = 'Creada'
      break
    case 'pagada':
      name = 'Pagada'
      break
    case 'cobrada':
      name = 'Cobrada'
      break
    default:
      name = status
      break
  }

  return name
}

export const getStatusBadge = (status, type = 'general') => {
  let badge = ''
  let label = ''

  // MAPEO DE COLORES SEMÁNTICO Y COMPLETO
  // -----------------------------------
  // awaiting: Azul marino - Estados que esperan alguna acción inicial
  // validating: Azul brillante - Estados que están en proceso de validación o revisión
  // pending: Azul brillante variante - Estados que están pendientes de alguna acción posterior
  // rejected: Rojo - Estados que indican rechazo o error
  // success: Verde brillante - Estados exitosos o completados positivamente
  // neutral: Marrón claro - Estados neutrales, suspendidos o inactivos
  // postponed: Púrpura - Estados pospuestos o retrasados
  // closed: Azul marino oscuro - Estados finalizados o cerrados
  // invalid: Rojo variante - Estados inválidos
  // Especial "creada": Turquesa - Estado particular para elementos recién creados

  // Mapeo centralizado de estados a clases de color
  const statusColorMap = {
    // EXPEDIENTES
    awaiting_acceptation: 'awaiting', // Azul marino
    awaiting_sepa: 'validating', // Azul brillante
    payment_rejected_sepa: 'rejected', // Rojo
    celebration_pending: 'pending', // Azul brillante variante
    celebrated: 'success', // Verde brillante
    posponed: 'postponed', // Púrpura
    suspended: 'neutral', // Verde oscuro
    canceled: 'rejected', // Rojo
    closed: 'closed', // Azul marino oscuro

    // FACTURAS
    proforma: 'neutral', // Verde oscuro
    creada: 'turquoise', // Turquesa (Nueva clase CSS)
    pagada: 'success', // Verde brillante
    cobrada: 'validating', // Azul brillante
    'pendiente de creación': 'pending', // Azul brillante variante

    // PROYECTOS
    awaiting_documentation: 'awaiting', // Azul marino
    project_review: 'validating', // Azul brillante
    to_review: 'validating', // Azul brillante
    in_progress: 'in-progress', // Amarillo (Nueva clase CSS)
    delivered: 'success', // Verde brillante
    reviewed: 'success', // Verde brillante
    assigned: 'assigned', // Verde azulado (Nueva clase CSS)
    finished: 'closed', // Azul marino oscuro
    cancelled: 'rejected', // Rojo
    awaiting_assignation: 'awaiting', // Azul marino

    // SEPA
    pending: 'pending', // Azul brillante variante
    created: 'turquoise', // Turquesa (Nueva clase CSS)
    late_failure_settled: 'rejected', // Rojo
    chargeback_settled: 'rejected', // Rojo
    surcharge_fee_debited: 'rejected', // Rojo
    failed: 'rejected', // Rojo
    chargeback: 'rejected', // Rojo
    paid_out: 'validating', // Azul brillante

    // VALIDACIÓN
    valido: 'success', // Verde brillante
    invalido: 'invalid', // Rojo variante

    // BRIEFS
    review: 'validating', // Azul brillante
    completed: 'success', // Verde brillante

    // URGENCIAS
    urgent: 'urgent', // Naranja (Nueva clase CSS)

    // REMESAS
    processed: 'success', // Verde brillante
    not_processed: 'pending', // Azul brillante variante
    sent: 'validating', // Azul brillante
    error: 'rejected', // Rojo
  }

  // Clase de color por defecto si el estado no está mapeado
  let colorClass = statusColorMap[status] || 'neutral'

  // Mapeo de estados a etiquetas según el tipo
  const statusLabelMap = {
    general: {
      awaiting_acceptation: 'Pend. aceptación',
      awaiting_documentation: 'Documentación pendiente',
      awaiting_assignation: 'Esperando asignación',
      awaiting_sepa: 'Validando',
      project_review: 'Revisando documentación',
      to_review: 'En revisión',
      payment_rejected_sepa: 'Rechazado',
      canceled: 'Cancelado',
      cancelled: 'Cancelado',
      celebration_pending: 'Pend. celebración',
      in_progress: 'En proceso',
      pending: 'Pendiente',
      celebrated: 'Celebrada',
      delivered: 'Entregado',
      assigned: 'Asignado',
      pagada: 'Pagada',
      valido: 'Válido',
      closed: 'Cerrado',
      finished: 'Finalizado',
      posponed: 'Pospuesta',
      suspended: 'Suspendida',
      proforma: 'Proforma',
      creada: 'Creada',
      cobrada: 'Cobrada',
      paid_out: 'Cobrada',
      invalido: 'Inválido',
      'pendiente de creación': 'Pend. creación',
      review: 'En revisión',
      completed: 'Completado',
      processed: 'Procesada',
      not_processed: 'No procesada',
    },
    invoice: {
      // Etiquetas específicas para facturas si difieren del general
    },
    expedient: {
      // Etiquetas específicas para expedientes si difieren del general
    },
    project: {
      // Etiquetas específicas para proyectos si difieren del general
    },
    sepa: {
      created: 'Creado',
      late_failure_settled: (status) => `Error: ${status}`,
      chargeback_settled: (status) => `Error: ${status}`,
      surcharge_fee_debited: (status) => `Error: ${status}`,
      failed: (status) => `Error: ${status}`,
      chargeback: (status) => `Error: ${status}`,
    },
    brief: {
      // Etiquetas específicas para briefs
    },
    remittance: {
      processed: 'Procesada',
      not_processed: 'Sin procesar',
      pending: 'Pendiente',
      created: 'Creada',
      sent: 'Enviada',
      failed: 'Fallida',
      error: 'Error',
    },
  }

  // Determinar la etiqueta del estado
  if (statusLabelMap[type] && statusLabelMap[type][status]) {
    // Si hay una función para generar la etiqueta
    if (typeof statusLabelMap[type][status] === 'function') {
      label = statusLabelMap[type][status](status)
    } else {
      label = statusLabelMap[type][status]
    }
  } else if (statusLabelMap['general'][status]) {
    // Usar etiqueta general si no hay específica
    if (typeof statusLabelMap['general'][status] === 'function') {
      label = statusLabelMap['general'][status](status)
    } else {
      label = statusLabelMap['general'][status]
    }
  } else {
    // Usar el status como etiqueta si no hay mapeo
    label = status
  }

  // Ya no necesitamos caso especial para estilos inline, ahora todo usa clases CSS
  badge = (
    <span
      className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
    >
      {label}
    </span>
  )

  return badge
}

export const invoiceStatusBadge = (status) => {
  return getStatusBadge(status, 'invoice')
}

export const expedientStatusBadge = (status) => {
  return getStatusBadge(status, 'expedient')
}

export const projectStatusBadge = (status) => {
  return getStatusBadge(status, 'project')
}

export const sepaStatusBadge = (status) => {
  return getStatusBadge(status, 'sepa')
}

export const briefStatusBadge = (status) => {
  return getStatusBadge(status, 'brief')
}

export const remittanceStatusBadge = (status) => {
  return getStatusBadge(status, 'remittance')
}

// Función especial para badges con iconos
export const expedientStatusBadgeIcon = (status) => {
  let badge = ''
  let colorClass = 'neutral' // Clase de color por defecto

  // Usar el mismo mapeo de la función centralizada
  const statusColorMap = {
    awaiting_acceptation: 'awaiting',
    awaiting_sepa: 'validating',
    payment_rejected_sepa: 'rejected',
    celebration_pending: 'pending',
    celebrated: 'success',
    posponed: 'postponed',
    suspended: 'neutral',
    canceled: 'rejected',
    closed: 'closed',
    proforma: 'neutral',
    creada: 'turquoise',
    pagada: 'success',
    'pendiente de creación': 'pending',
    valido: 'success',
    invalido: 'invalid',
    processed: 'success',
    not_processed: 'pending',
  }

  // Determinar la clase de color
  if (statusColorMap[status]) {
    colorClass = statusColorMap[status]
  }

  switch (status) {
    case 'awaiting_acceptation':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <HelpCircle size={9} />
        </span>
      )
      break
    case 'awaiting_sepa':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Clock size={9} /> Val
        </span>
      )
      break
    case 'payment_rejected_sepa':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <XCircle size={9} /> Rech
        </span>
      )
      break
    case 'celebration_pending':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Clock size={9} />
        </span>
      )
      break
    case 'celebrated':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Check size={9} />
        </span>
      )
      break
    case 'posponed':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <ArrowRight size={9} />
        </span>
      )
      break
    case 'suspended':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <XCircle size={9} />
        </span>
      )
      break
    case 'canceled':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <XCircle size={9} />
        </span>
      )
      break
    case 'closed':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Lock size={9} />
        </span>
      )
      break
    case 'proforma':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <HelpCircle size={9} />
        </span>
      )
      break
    case 'creada':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Check size={9} />
        </span>
      )
      break
    case 'pagada':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Check size={9} />
        </span>
      )
      break
    case 'pendiente de creación':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Clock size={9} /> Pend
        </span>
      )
      break
    case 'valido':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Check size={9} />
        </span>
      )
      break
    case 'invalido':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <XCircle size={9} />
        </span>
      )
      break
    case 'processed':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <Check size={9} />
        </span>
      )
      break
    case 'not_processed':
      badge = (
        <span
          className={`pigeonAI-status-colors-badge pigeonAI-status-colors-${colorClass} me-1 mb-1`}
        >
          <XCircle size={9} />
        </span>
      )
      break
    default:
      break
  }

  return badge
}

export const getRawDate = (date) => {
  const y = moment(date).year()
  const month = moment(date).format('MM')
  const day = moment(date).format('DD')

  const h = moment(date).format('HH')
  const m = moment(date).format('mm')
  const s = moment(date).format('ss')

  const raw = `${y}-${month}-${day}T${h}:${m}:${s}`
  return raw
}

export const getRawLocalDate = (raw_hearing_date) => {
  const raw_date = moment.utc(raw_hearing_date)

  const date = new Date(
    raw_date.format('YYYY'),
    raw_date.format('MM') - 1,
    raw_date.format('DD')
  )

  date.setHours(raw_date.format('HH'))
  date.setMinutes(raw_date.format('mm'))
  date.setSeconds(raw_date.format('ss'))

  return date
}

export const roundToTwoDecimals = (value) => Math.round(value * 100) / 100

export const getProfileImage = async (image, showToast = false) => {
  if (image.status === 'deleted') {
    return
  }
  if (image && image.status !== 'deleted') {
    try {
      const response = await fetch(`${baseAPI}/get-signed-image/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(image),
      })
      const res = await response.json()
      return res
    } catch (err) {
      showToast &&
        toast.error('Ha ocurrido un error cargando la imagen', {
          theme: 'colored',
        })
      return null
    }
  }
  showToast &&
    toast.error('Ha ocurrido un error cargando la imagen', { theme: 'colored' })
  return null
}

export const isOlderThanXdays = (date, days) => {
  const today = moment(new Date().setUTCSeconds(30))
  const differenceOfDays = today.diff(date, 'days')
  return differenceOfDays >= days
}

const getBadgeColor = (counter) => {
  if (counter <= 1) return 'danger'
  if (counter <= 3) return 'warning'
  return 'success'
}

export const CounterBadge = React.memo(({ counter }) => (
  <span className={`badge rounded-pill me-1 mb-1 bg-${getBadgeColor(counter)}`}>
    {counter}
  </span>
))

export const renderCreatorField = (
  document,
  creatorIsPopulated,
  activeUser,
  field
) => {
  if (activeUser?.role === 'admin') {
    return creatorIsPopulated ? (
      <a
        href={`${field === 'email' ? 'mailto:' : 'tel:'}${
          field === 'email'
            ? document?.creator_id['alternative_email'] ||
              document?.creator_id[field]
            : document?.creator_id[field]
        }`}
      >
        {field === 'email'
          ? document?.creator_id['alternative_email'] ||
            document?.creator_id[field]
          : document?.creator_id[field]}
      </a>
    ) : (
      '***'
    )
  }
  return isOlderThanXdays(document?.hearing_date ?? document?.deadline, 10) &&
    (activeUser?.is_substitute || activeUser?.is_procurador) &&
    activeUser?._id === document?.substitute_id._id ? (
    '***'
  ) : creatorIsPopulated ? (
    <a
      href={`${field === 'email' ? 'mailto:' : 'tel:'}${
        field === 'email'
          ? document?.creator_id['alternative_email'] ||
            document?.creator_id[field]
          : document?.creator_id[field]
      }`}
    >
      {field === 'email'
        ? document?.creator_id['alternative_email'] ||
          document?.creator_id[field]
        : document?.creator_id[field]}
    </a>
  ) : (
    '***'
  )
}

export const renderSubstituteField = (
  document,
  substituteIsPopulated,
  activeUser,
  field
) => {
  if (activeUser?.role === 'admin') {
    return substituteIsPopulated ? (
      <a
        href={`${field === 'email' ? 'mailto:' : 'tel:'}${
          document?.substitute_id[field]
        }`}
      >
        {document?.substitute_id[field]}
      </a>
    ) : (
      '***'
    )
  }
  return isOlderThanXdays(document?.hearing_date ?? document?.deadline, 10) &&
    ['client', 'company', 'partner'].includes(activeUser?.role) ? (
    '***'
  ) : substituteIsPopulated ? (
    <a
      href={`${field === 'email' ? 'mailto:' : 'tel:'}${
        document?.substitute_id[field]
      }`}
    >
      {document?.substitute_id[field]}
    </a>
  ) : (
    '***'
  )
}

export const getDocType = ({ projectst }) => {
  const { skill, project_type, doc_type } = projectst

  const projectType =
    skill && project_st_type[skill].find((pt) => pt.id === project_type)
  const docType = projectType?.doc_type.find((doc) => doc.id === doc_type)
  return docType
}

const getExperienceMultiplier = (experience) => {
  switch (experience) {
    case 'paralegal':
      return 0.8
    case 'midSenior':
      return 1.3
    case 'senior':
      return 1.6
    case 'junior':
      return 1.0
    default:
      return 1.0
  }
}

const getWorkTypeMultiplier = (work_type) => {
  switch (work_type) {
    case 'onSite':
      return 1.1
    case 'hybrid':
      return 1.05
    case 'remote':
      return 1.0
    default:
      return 1.0
  }
}

const getJourneyTypeMultiplier = (journey_type) => {
  switch (journey_type) {
    case 'partTime':
      return 0.5
    case 'fullTime':
      return 1.0
    default:
      return 1.0
  }
}

export const calcBudget = ({ experience, work_type, journey_type }) => {
  let precioBase = ONBOARD_BASE_PRICE || 100

  precioBase *= getExperienceMultiplier(experience)
  precioBase *= getWorkTypeMultiplier(work_type)
  precioBase *= getJourneyTypeMultiplier(journey_type)

  const finalPrice = precioBase
  return finalPrice.toFixed(0)
}

export const resumePlaceholders = [
  'Ejemplo: Somos demandantes en un caso de despido improcedente en una empresa manufacturera.',
  'Ejemplo: Somos demandados en una reclamación de daños y perjuicios por accidente de tráfico.',
  'Ejemplo: Nulidad contrato por usura. Defendemos a la parte demandada o demandante.',
  'Ejemplo: Proceso por fraude fiscal y evasión de impuestos.',
  'Ejemplo: Defendemos al perjudicado en un juicio por delitos contra la salud pública',
  'Ejemplo: Somos demandados en una reclamación de cantidad por impago de factura.',
  'Ejemplo: Representamos a la empresa en un caso de acoso laboral y discriminación en el trabajo.',
  'Ejemplo: Divorcio contencioso con disputa por la custodia de los hijos.',
  'Ejemplo: Somos actores en un litigio por cláusula suelo en préstamos hipotecarios.',
  'Ejemplo: Demanda por incumplimiento de contrato de compraventa de bienes inmuebles. Somos demandados.',
  'Ejemplo: Defensa en un caso de robo agravado con uso de violencia.',
  'Ejemplo: Reclamación por uso abusivo de tarjetas revolving. Somos actores. ',
  'Ejemplo: Litigio por estafa y abuso de confianza en una relación comercial.',
  'Ejemplo: Proceso de desahucio precario por ocupación ilegal de propiedad. Representamos al propietario.',
  'Ejemplo: Representamos al trabajador en una reclamación de pagos atrasados y horas extra no remuneradas.',
]

export const getJudicialPartyName = ({ document }) => {
  return judicialParties.find(
    (jp) => String(jp.id) === String(document.judicial_party)
  )?.name
}

export const getJudicialPartyNameById = (judicial_party) => {
  return judicialParties.find((jp) => String(jp.id) === String(judicial_party))
    ?.name
}

export const getHearingTypeName = ({ document }) => {
  return hearingDefaultList.find((ht) => ht.id === document.hearing_type)?.value
}

export const products = {
  expedients: {
    name: 'Expedientes',
    description: 'expedientes',
    type: 'expedients',
    mainURL: 'expedient',
    downloadLink:
      'https://drive.google.com/uc?export=download&id=1uBwbLkDfVs3_ZjP8fMPsXZvImSqs6VJ1',
    validateDrafts: `${baseAPI}/import/validation`,
    importDrafts: `${baseAPI}/import/`,
    deleteDrafts: `${baseAPI}/import/delete`,
    assignDrafts: `${baseAPI}/import/assignCreate`,
    importType: 'expedient',
    assignType: 'expedient',
    gtmItemId: 'EI',
    loadDocuments: loadDrafts,
  },
  procura: {
    name: 'Pigeon Procura',
    description: 'Pigeon Procura',
    type: 'procura',
    mainURL: 'procura',
    downloadLink:
      'https://drive.google.com/uc?export=download&id=1JIZ8NvMc2p04T6EqKWebDgl2v-rK69-X',
    validateDrafts: `${baseAPI}/import-procura/validation`,
    importDrafts: `${baseAPI}/import-procura/`,
    deleteDrafts: `${baseAPI}/import-procura/delete`,
    assignDrafts: `${baseAPI}/import-procura/assignCreate`,
    importType: 'procura',
    assignType: 'procura',
    gtmItemId: 'PROCI',
    loadDocuments: loadProcuraDrafts,
  },
  onCall: {
    name: 'Pigeon OnCall',
    description: 'Pigeon On Call',
    type: 'onCall',
    mainURL: 'projects-short-term',
    downloadLink:
      'https://drive.google.com/uc?export=download&id=1-IlG9Feofq8ao5vkhESZdBV1Kd9EUi2B',
    validateDrafts: `${baseAPI}/import-oncall/validation`,
    importDrafts: `${baseAPI}/import-oncall/`,
    deleteDrafts: `${baseAPI}/import-oncall/delete`,
    assignDrafts: `${baseAPI}/import-oncall/assignCreate`,
    importType: 'onCall',
    assignType: 'projectst',
    gtmItemId: 'OCI',
    loadDocuments: loadOnCallDrafts,
  },
  // inHouse: {
  //   name: 'Pigeon InHouse',
  //   description: 'Pigeon In House',
  //   type: 'inHouse',
  //   downloadLink: '#',
  //   'https://drive.google.com/uc?export=download&id=1uBwbLkDfVs3_ZjP8fMPsXZvImSqs6VJ1',
  //   validationImport: `${baseAPI}/import-inhouse/validation`,
  //   importDocuments: `${baseAPI}/import-inhouse/`,
  //   importType: 'inHouse',
  //   gtmItemId: 'IHI',
  //   loadDocuments: loadInHouseDrafts,
  // },
}

export const getInvoiceLink = ({ invoice }) => {
  const { expedient_id, expedient_ids, document_type, document_ids } = invoice

  const links = {
    expedient1: `/panel/expedients/${expedient_id || expedient_ids[0]}`,
    expedient2: `/panel/expedients/${document_ids[0]}`,
    procura: `/panel/procura/${document_ids[0]}`,
    onCall: `/panel/projects-short-term/${document_ids[0]}`,
    inHouse: `/panel/projects-long-term/${document_ids[0]}`,
  }

  let objectLink = {}

  if (document_type === 'procura') {
    objectLink = { linkTo: links.procura, linkDesc: document_ids[0] }
  }
  if (
    ['onCall', 'projectST', 'project-shor-term', 'oncall'].includes(
      document_type
    )
  ) {
    objectLink = { linkTo: links.onCall, linkDesc: document_ids[0] }
  }
  if (
    ['inHouse', 'projectLT', 'project-long-term', 'inhouse'].includes(
      document_type
    )
  ) {
    objectLink = { linkTo: links.inHouse, linkDesc: document_ids[0] }
  }
  if (['expedient', 'expedients', undefined].includes(document_type)) {
    if (expedient_id || expedient_ids[0]) {
      objectLink = {
        linkTo: links.expedient1,
        linkDesc: expedient_id || expedient_ids[0],
      }
    } else {
      objectLink = { linkTo: links.expedient2, linkDesc: document_ids[0] }
    }
  }
  return objectLink
}

export const userPriceProductOptions = [
  { value: 'procura', label: 'Procura' },
  { value: 'expedient', label: 'Expedientes' },
]

export const budgetStatusName = (status) => {
  let name = ''

  switch (status) {
    case 'pending':
      name = 'Pendiente'
      break
    case 'consolidated':
      name = 'Consolidado'
      break
    case 'cancelled':
      name = 'Cancelado'
      break
    case 'reassigned':
      name = 'Reasignado'
      break
    default:
      name = 'Desconocido'
  }

  return name
}

export const budgetStatusBadge = (status) => {
  let color = ''
  let icon = null

  switch (status) {
    case 'pending':
      color = 'warning'
      icon = <Clock size={14} className="me-1" />
      break
    case 'consolidated':
      color = 'success'
      icon = <Check size={14} className="me-1" />
      break
    case 'cancelled':
      color = 'danger'
      icon = <XCircle size={14} className="me-1" />
      break
    case 'reassigned':
      color = 'info'
      icon = <ArrowRight size={14} className="me-1" />
      break
    default:
      color = 'secondary'
      icon = <HelpCircle size={14} className="me-1" />
  }

  return {
    color,
    icon,
    name: budgetStatusName(status),
  }
}

export const documentTypeName = (type) => {
  let name = ''
  switch (type) {
    case 'Expedient':
      name = 'Expediente'
      break
    case 'Procura':
      name = 'Procura'
      break
    case 'onCall':
      name = 'On Call'
      break
    case 'onBoard':
      name = 'On Board'
      break
    default:
      name = 'Desconocido'
  }

  return name
}
