import { useEffect, useState } from 'react'
import { baseAPI } from '../../config'

//componetn to display the project LT counter
const ProjectLTCounter = () => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    async function fetchData() {
      const url = `${baseAPI}/projectlt/count`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      setCount(data.totalProjects)
    }
    fetchData()
  }, [])
  return (
    <>
      {!!count && (
        <span className="badge rounded-circle bg-danger ms-2">{count}</span>
      )}
    </>
  )
}

export default ProjectLTCounter
