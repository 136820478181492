import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  remittances: {},
  loading: false,
}

export const remittance = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_REMITTANCES_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.GET_REMITTANCES_SUCCESS:
      return {
        ...state,
        remittances: action.payload,
        loading: false,
      }

    case types.GET_REMITTANCES_ERROR:
      return {
        ...state,
        loading: false,
      }

    case types.SET_REMITTANCE_PROCESSED_SUCCESS:
      const index = state.remittances.docs.findIndex(
        (rem) => rem._id === action.payload.remittance._id
      )
      const newDocs = [
        ...state.remittances.docs.slice(0, index),
        action.payload.remittance,
        ...state.remittances.docs.slice(index + 1),
      ]

      return {
        ...state,
        remittances: {
          ...state.remittances,
          docs: newDocs,
        },
      }

    case types.REPEAT_INVOICE_REMITTANCE_SUCCESS:
      if (action.payload && action.payload.remittance) {
        const index = state.remittances.docs.findIndex(
          (rem) => rem._id === action.payload.remittance._id
        )
        if (index !== -1) {
          const newDocs = [
            ...state.remittances.docs.slice(0, index),
            action.payload.remittance,
            ...state.remittances.docs.slice(index + 1),
          ]
          return {
            ...state,
            remittances: {
              ...state.remittances,
              docs: newDocs,
            },
            loading: false,
          }
        }
      }
      return {
        ...state,
        loading: false,
      }

    case types.REPEAT_REMITTANCE_SUCCESS:
      if (action.payload && action.payload.remittance) {
        const index = state.remittances.docs.findIndex(
          (rem) => rem._id === action.payload.remittance._id
        )
        if (index !== -1) {
          const newDocs = [
            ...state.remittances.docs.slice(0, index),
            action.payload.remittance,
            ...state.remittances.docs.slice(index + 1),
          ]
          return {
            ...state,
            remittances: {
              ...state.remittances,
              docs: newDocs,
            },
            loading: false,
          }
        }
      }
      return {
        ...state,
        loading: false,
      }

    case types.ROLLBACK_REMITTANCE_SUCCESS:
      if (action.payload && action.payload.remittance) {
        const index = state.remittances.docs.findIndex(
          (rem) => rem._id === action.payload.remittance._id
        )
        if (index !== -1) {
          const newDocs = [
            ...state.remittances.docs.slice(0, index),
            action.payload.remittance,
            ...state.remittances.docs.slice(index + 1),
          ]
          return {
            ...state,
            remittances: {
              ...state.remittances,
              docs: newDocs,
            },
            loading: false,
          }
        }
      }
      return {
        ...state,
        loading: false,
      }
    case types.ROLLBACK_INVOICE_REMITTANCE_SUCCESS:
      if (action.payload && action.payload.remittance) {
        const index = state.remittances.docs.findIndex(
          (rem) => rem._id === action.payload.remittance._id
        )
        if (index !== -1) {
          const newDocs = [
            ...state.remittances.docs.slice(0, index),
            action.payload.remittance,
            ...state.remittances.docs.slice(index + 1),
          ]
          return {
            ...state,
            remittances: {
              ...state.remittances,
              docs: newDocs,
            },
            loading: false,
          }
        }
      }
      return {
        ...state,
        loading: false,
      }
    case types.IMPORT_HOLDED_REMITTANCE_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case types.SET_REMITTANCE_PROCESSED_REQUEST:
    case types.REPEAT_INVOICE_REMITTANCE_REQUEST:
    case types.REPEAT_REMITTANCE_REQUEST:
    case types.ROLLBACK_REMITTANCE_REQUEST:
    case types.ROLLBACK_INVOICE_REMITTANCE_REQUEST:
    case types.IMPORT_HOLDED_REMITTANCE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.SET_REMITTANCE_PROCESSED_ERROR:
    case types.REPEAT_INVOICE_REMITTANCE_ERROR:
    case types.ROLLBACK_REMITTANCE_ERROR:
    case types.ROLLBACK_INVOICE_REMITTANCE_ERROR:
    case types.IMPORT_HOLDED_REMITTANCE_ERROR:
      toast.error(action.payload.response.message, { theme: 'colored' })
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
