import {
  faPersonWalking,
  faGavel,
  faHandshakeSimple,
  faFileSignature,
  faEye,
  faScaleBalanced,
  faGraduationCap,
  faBriefcase,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { ENABLE_PIGEON_IN_HOUSE } from '../../../config'
import pigeonalavista from '../../../assets/images/pigeonalavista.png'
import pigeonLegalServices from '../../../assets/images/PigeonOnCall/pigeonOnCallOption.png'
import pigeonNotary from '../../../assets/images/pigeonNotary.png'
import pigeonPropertyServices from '../../../assets/images/pigeonPropertyServices.png'

export const options = [
  {
    title: 'Pigeon a la vista',
    content:
      '¿Buscas a un <span style="font-weight:900;">abogado o procurador</span> para que te sustituya en una actuación judicial? ',
    fontAwesome: faGavel,
    show: true,
    isNew: false,
    isCommingSoon: false,
    imgSrc: pigeonalavista,
    suboptions: [
      {
        title: 'Pigeon Abogado',
        content: '¡Contrata a tu letrado sustituto!.',
        fontAwesome: faGraduationCap,
        to: '/panel/hearing',
        show: true,
        isNew: false,
        isCommingSoon: false,
      },
      {
        title: 'Pigeon Procura',
        content: '¡Contrata a tu procurador sustituto!',
        fontAwesome: faScaleBalanced,
        to: '/panel/new-procura',
        show: true,
        isNew: new Date() < new Date('2024-12-31'),
        isCommingSoon: false,
      },
    ],
  },
  {
    title: 'Pigeon Legal Services',
    content: '¡Externaliza tus necesidades jurídicas!',
    fontAwesome: faFileSignature,
    show: true,
    isNew: true,
    isCommingSoon: false,
    imgSrc: pigeonLegalServices,
    suboptions: [
      {
        title: 'Pigeon On Call',
        content:
          '¿Necesitas confeccionar y redactar escritos judiciales o extrajudiciales?',
        fontAwesome: faHandshakeSimple,
        to: '/panel/project-short-term',
        show: true,
        isNew: new Date() < new Date('2024-07-31'),
        isCommingSoon: false,
      },
      {
        title: 'Pigeon On Board',
        content:
          '¿Necesitas un abogado disponible por un tiempo determinado para un proyecto concreto?',
        fontAwesome: faPersonWalking,
        to: '/panel/new-pigeon-onboard',
        show: ENABLE_PIGEON_IN_HOUSE,
        isNew: true,
        isCommingSoon: !ENABLE_PIGEON_IN_HOUSE,
        disabled: !ENABLE_PIGEON_IN_HOUSE,
      },
    ],
  },
  {
    title: 'Pigeon Notaria',
    content:
      '¿Necesitas un abogado que te acompañe en tus trámites notariales?',
    fontAwesome: faBriefcase,
    show: true,
    isNew: false,
    isCommingSoon: false,
    imgSrc: pigeonNotary,
    suboptions: [
      {
        title: 'Notaria - Con apoderado',
        content:
          '¡Delega todos los trámites notariales en un representante autorizado!',
        fontAwesome: faBriefcase,
        to: '/panel/hearing/notary-with-powers',
        show: true,
        isNew: false,
        isCommingSoon: false,
      },
      {
        title: 'Notaria - Sin apoderado',
        content: '¿Necesitas un letrado para que acuda a un trámite notarial?',
        fontAwesome: faUser,
        to: '/panel/hearing/notary-without-powers',
        show: true,
        isNew: false,
        isCommingSoon: false,
      },
    ],
  },
  {
    title: 'Pigeon Property Services',
    content: '¿Necesitas gestionar propiedades de forma eficiente?',
    fontAwesome: faEye,
    show: true,
    isNew: false,
    isCommingSoon: true,
    disabled: true,
    imgSrc: pigeonPropertyServices,
    suboptions: [
      {
        title: 'Informe posesorio',
        content:
          '¡Obtén un análisis detallado del estado posesorio de cualquier inmueble que necesites evaluar!',
        fontAwesome: faBriefcase,
        to: '/panel/hearing/informe-posesorio',
        show: true,
        isNew: false,
        isCommingSoon: true,
        disabled: false,
      },
      {
        title: 'Entrega de llaves',
        content:
          'Facilitamos la transición entre propietarios e inquilinos, asegurando el proceso de entrega y recepción de llaves sea claro.',
        fontAwesome: faUser,
        to: '/panel/hearing/notary-without-powers',
        show: true,
        isNew: false,
        isCommingSoon: true,
        disabled: true,
      },
      {
        title: 'Junta de comunidad de propietarios',
        content:
          '¿Necesitas a un abogado para que asista a una junta de comunidad de propietarios?',
        fontAwesome: faUser,
        to: '/panel/hearing/notary-without-powers',
        show: true,
        isNew: false,
        isCommingSoon: true,
        disabled: true,
      },
      {
        title: 'Otros',
        content:
          '¿Necesitas algún otro servicio no contemplado? Contacta con nosotros',
        fontAwesome: faUser,
        to: '/panel/hearing/notary-without-powers',
        show: true,
        isNew: false,
        isCommingSoon: true,
        disabled: true,
      },
    ],
  },
]
