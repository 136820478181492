import {
  handleError,
  handleSuccess,
} from '../../helpers/toastHelpers/toastHandlers'
import toastTypes from '../../helpers/toastHelpers/toastTypes'
import * as types from '../constants'

const { errorTypes, successTypes } = toastTypes

const toastMiddleware = () => (next) => (action) => {
  switch (action.type) {
    // #region users.js
    case types.CREATE_CLIENT_SUCCESS:
      handleSuccess(successTypes.CREATE_CLIENT_SUCCESS)
      return next(action)

    case types.CREATE_CLIENT_ERROR:
      handleError(errorTypes.CREATE_CLIENT_ERROR)
      return next(action)
    case types.CREATE_USER_ERROR:
      handleError(errorTypes.CREATE_USER_ERROR)
      return next(action)

    case types.UPDATE_USER_SUCCESS:
      if (action.payload.user.imageValid === false) {
        handleError(errorTypes.UPDATE_USER_SUCCESS_IMAGE_NOT_VALID)
      } else {
        handleSuccess(successTypes.UPDATE_USER_SUCCESS)
      }
      return next(action)

    case types.UPDATE_USER_ERROR:
      if (
        action.payload?.response?.message?.errmsg?.includes('cif_1 dup key:') ||
        action.payload?.response?.message?.keyValue?.cif
      ) {
        handleError(errorTypes.UPDATE_USER_ERROR_CIF_USED)
      } else {
        handleError(
          action.payload.response?.message || errorTypes.UPDATE_USER_ERROR
        )
      }
      return next(action)

    case types.DELETE_USER_SUCCESS:
      handleSuccess(successTypes.DELETE_USER_SUCCESS)
      return next(action)

    case types.DELETE_USER_ERROR:
      handleError(
        action.payload.response.message
          ? action.payload.response.message
          : errorTypes.DELETE_USER_ERROR
      )
      return next(action)

    case types.CREATE_USER_SUCCESS:
      handleSuccess(successTypes.CREATE_USER_SUCCESS)
      return next(action)
    // #endregion

    // #region partners.js
    case types.ADD_SUBSTITUTE_EXCLUDED_ERROR:
      handleError(errorTypes.ADD_SUBSTITUTE_EXCLUDED_ERROR)
      return next(action)

    case types.ADD_SUBSTITUTE_EXCLUDED_SUCCESS:
      handleSuccess(successTypes.ADD_SUBSTITUTE_EXCLUDED_SUCCESS)
      return next(action)

    case types.REMOVE_SUBSTITUTE_EXCLUDED_ERROR:
      handleError(errorTypes.REMOVE_SUBSTITUTE_EXCLUDED_ERROR)
      return next(action)

    case types.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS:
      handleSuccess(successTypes.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS)
      return next(action)

    case types.SET_LINKED_PARTNERS_ERROR:
      handleError(errorTypes.SET_LINKED_PARTNERS_ERROR)
      return next(action)

    case types.DELETE_LINKED_PARTNER_ERROR:
      handleError(errorTypes.DELETE_LINKED_PARTNER_ERROR)
      return next(action)

    case types.CHANGE_PARTNER_TO_COMPANY_SUCCESS:
      handleSuccess(successTypes.CHANGE_PARTNER_TO_COMPANY_SUCCESS)
      return next(action)

    case types.CHANGE_PARTNER_TO_COMPANY_ERROR:
      handleError(errorTypes.CHANGE_PARTNER_TO_COMPANY_ERROR)
      return next(action)

    case types.UPDATE_USER_FEEDBACK_SCORE_SUCCESS:
      handleSuccess(successTypes.UPDATE_USER_FEEDBACK_SCORE_SUCCESS)
      return next(action)

    case types.UPDATE_USER_FEEDBACK_SCORE_ERROR:
      handleError(errorTypes.UPDATE_USER_FEEDBACK_SCORE_ERROR)
      return next(action)
    // #endregion

    // #region customFields.js
    case types.ADD_CUSTOM_FIELD_ERROR:
      if (action.payload.status === 400) {
        handleError(errorTypes.ADD_CUSTOM_FIELD_ERROR)
      }
      return next(action)

    case types.UPDATE_CUSTOM_FIELD_ERROR:
      if (action.payload.status === 400) {
        handleError(errorTypes.UPDATE_CUSTOM_FIELD_ERROR)
      }
      return next(action)
    // #endregion

    // #region authPools.js
    case types.ADD_AUTH_POOL_SUCCESS:
    case types.EDIT_AUTH_POOL_SUCCESS:
      handleSuccess(successTypes.ADD_AUTH_POOL_SUCCESS)
      return next(action)

    case types.ADD_AUTH_POOL_ERROR:
    case types.EDIT_AUTH_POOL_ERROR:
      if (action.payload.status === 409) {
        handleError(errorTypes.ADD_AUTH_POOL_ERROR)
      } else {
        handleError(errorTypes.EDIT_AUTH_POOL_ERROR)
      }
      return next(action)
    // #endregion

    // #region favorites.js
    case types.ADD_FAVORITE_SUBSTITUTE_SUCCESS:
      handleSuccess(successTypes.ADD_FAVORITE_SUBSTITUTE_SUCCESS)
      return next(action)

    case types.REMOVE_FAVORITE_SUBSTITUTE_SUCCESS:
      handleSuccess(successTypes.REMOVE_FAVORITE_SUBSTITUTE_SUCCESS)
      return next(action)
    // #endregion

    // #region projects.js
    case types.CREATE_PROJECT_LONG_TERM_SUCCESS:
      handleSuccess(successTypes.CREATE_PROJECT_LONG_TERM_SUCCESS)
      return next(action)

    case types.CREATE_PROJECT_LONG_TERM_ERROR:
      if (action.payload.status === 404) {
        handleError(errorTypes.CREATE_PROJECT_LONG_TERM_ERROR_404)
      } else {
        handleError(errorTypes.CREATE_PROJECT_LONG_TERM_ERROR_GENERIC)
      }
      return next(action)

    case types.LOAD_PROJECTS_LONG_TERM_ERROR:
      handleError(errorTypes.LOAD_PROJECTS_LONG_TERM_ERROR)
      return next(action)

    case types.LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR:
      handleError(errorTypes.LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR)
      return next(action)

    case types.LOAD_PROJECT_LONG_TERM_ERROR:
      handleError(errorTypes.LOAD_PROJECT_LONG_TERM_ERROR)
      return next(action)

    case types.UPDATE_PROJECT_LONG_TERM_SUCCESS:
      handleSuccess(successTypes.UPDATE_PROJECT_LONG_TERM_SUCCESS)
      return next(action)

    case types.UPDATE_PROJECT_LONG_TERM_ERROR:
      handleError(errorTypes.UPDATE_PROJECT_LONG_TERM_ERROR)
      return next(action)

    case types.DELETE_PROJECT_LONG_TERM_SUCCESS:
      handleSuccess(successTypes.DELETE_PROJECT_LONG_TERM_SUCCESS)
      return next(action)

    case types.DELETE_PROJECT_LONG_TERM_ERROR:
      handleError(errorTypes.DELETE_PROJECT_LONG_TERM_ERROR)
      return next(action)

    case types.PROJECT_LONG_DOC_RECLAMATION_SUCCESS:
      handleSuccess(successTypes.PROJECT_LONG_DOC_RECLAMATION_SUCCESS)
      return next(action)

    case types.PROJECT_LONG_DOC_RECLAMATION_ERROR:
      handleError(errorTypes.PROJECT_LONG_DOC_RECLAMATION_ERROR)
      return next(action)

    case types.REASSIGN_PROJECT_LONG_TERM_SUCCESS:
      handleSuccess(successTypes.REASSIGN_PROJECT_LONG_TERM_SUCCESS)
      return next(action)

    case types.REASSIGN_PROJECT_LONG_TERM_ERROR:
      handleError(errorTypes.REASSIGN_PROJECT_LONG_TERM_ERROR)
      return next(action)

    case types.APPLY_PROJECT_LONG_TERM_SUCCESS:
      handleSuccess(successTypes.APPLY_PROJECT_LONG_TERM_SUCCESS)
      return next(action)

    case types.APPLY_PROJECT_LONG_TERM_ERROR:
      handleError(errorTypes.APPLY_PROJECT_LONG_TERM_ERROR)
      return next(action)

    case types.ASSIGN_PROJECT_LONG_TERM_SUCCESS:
      handleSuccess(successTypes.ASSIGN_PROJECT_LONG_TERM_SUCCESS)
      return next(action)

    case types.ASSIGN_PROJECT_LONG_TERM_ERROR:
      handleError(errorTypes.ASSIGN_PROJECT_LONG_TERM_ERROR)
      return next(action)

    case types.CREATE_PROJECT_LONG_MEETING_SUCCESS:
      handleSuccess(successTypes.CREATE_PROJECT_LONG_MEETING_SUCCESS)
      return next(action)

    case types.CREATE_PROJECT_LONG_MEETING_ERROR:
      handleError(errorTypes.CREATE_PROJECT_LONG_MEETING_ERROR)
      return next(action)

    case types.DECLINE_PROJECT_LONG_TERM_CANDIDATE_SUCCESS:
      handleSuccess(successTypes.DECLINE_PROJECT_LONG_TERM_CANDIDATE_SUCCESS)
      return next(action)

    case types.DECLINE_PROJECT_LONG_TERM_CANDIDATE_ERROR:
      handleError(errorTypes.DECLINE_PROJECT_LONG_TERM_CANDIDATE_ERROR)
      return next(action)

    case types.SEND_PROJECT_LONG_TERM_EMAILS_SUCCESS:
      handleSuccess(successTypes.SEND_PROJECT_LONG_TERM_EMAILS_SUCCESS)
      return next(action)

    case types.SEND_PROJECT_LONG_TERM_EMAILS_ERROR:
      handleError(errorTypes.SEND_PROJECT_LONG_TERM_EMAILS_ERROR)
      return next(action)

    //#region User Reminders
    case types.CREATE_USER_REMINDER_STATUS_SUCCESS:
      handleSuccess(successTypes.CREATE_USER_REMINDER_STATUS_SUCCESS)
      return next(action)

    case types.CREATE_USER_REMINDER_STATUS_ERROR:
      handleSuccess(errorTypes.CREATE_USER_REMINDER_STATUS_ERROR)
      return next(action)

    // #region pigeonAI.js
    case types.PIGEONAI_UPLOAD_FILES_SUCCESS:
      handleSuccess('Archivos subidos correctamente')
      return next(action)

    case types.PIGEONAI_UPLOAD_FILES_ERROR:
      handleError(errorTypes.PIGEONAI_UPLOAD_FILES_ERROR)
      return next(action)

    case types.PIGEONAI_DOWNLOAD_FILE_ERROR:
      handleError(errorTypes.PIGEONAI_DOWNLOAD_FILE_ERROR)
      return next(action)

    case types.PIGEONAI_GET_STATUS_ERROR:
      if (action.payload.response?.instructa?.status === 'canceled') {
        handleError(errorTypes.PIGEONAI_INSTRUCTA_CANCELED)
      } else {
        handleError(errorTypes.PIGEONAI_GET_STATUS_ERROR)
      }
      return next(action)

    // #region projectCandidate.js

    case types.DELETE_PROJECT_CANDIDATE_SUCCESS:
      handleSuccess(successTypes.DELETE_PROJECT_CANDIDATE_SUCCESS)
      return next(action)

    case types.DELETE_PROJECT_CANDIDATE_ERROR:
      handleError(errorTypes.DELETE_PROJECT_CANDIDATE_ERROR)
      return next(action)

    case types.UPDATE_PROJECT_CANDIDATE_SUCCESS:
      handleSuccess(successTypes.UPDATE_PROJECT_CANDIDATE_SUCCESS)
      return next(action)

    case types.UPDATE_PROJECT_CANDIDATE_ERROR:
      handleError(errorTypes.UPDATE_PROJECT_CANDIDATE_ERROR)
      return next(action)

    case types.LOAD_CLIENT_DASHBOARD_ERROR:
      handleError(errorTypes.LOAD_CLIENT_DASHBOARD_ERROR)
      return next(action)

    default:
      return next(action)
  }
}

export default toastMiddleware
