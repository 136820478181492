import { AiOutlineRobot } from 'react-icons/ai'
import {
  Users,
  Inbox,
  ShoppingCart,
  Folder,
  Activity,
  Globe,
  Archive,
  Gift,
  Tool,
  Database,
  Home,
  MessageSquare,
  PhoneCall,
  Eye,
  Edit3,
  FileText,
  UserCheck,
  TrendingUp,
  CreditCard,
  Send,
  File,
  Divide,
  DollarSign,
  TrendingDown,
} from 'react-feather'

export const ICONS = {
  dashboard: Activity,
  pigeonAI: AiOutlineRobot,
  users: Users,
  inbox: Inbox,
  shoppingCart: ShoppingCart,
  folder: Folder,
  globe: Globe,
  archive: Archive,
  gift: Gift,
  tool: Tool,
  database: Database,
  home: Home,
  messageSquare: MessageSquare,
  phone: PhoneCall,
  views: Eye,
  notary: Edit3,
  onCall: PhoneCall,
  procura: UserCheck,
  drafts: FileText,
  incomeCompany: TrendingUp,
  incomeClients: TrendingUp,
  expenseSubstitutes: CreditCard,
  remittances: Send,
  escritos: File,
  divide: Divide,
  dollar: DollarSign,
  refund: TrendingDown,
}
