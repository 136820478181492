import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row>
        <Col xs="6" className="text-left">
          <span className="text-muted" href="#">
            Legal
          </span>
        </Col>
        <Col xs="6" className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{' '}
            <span href="/" className="text-muted text-right">
              Legal Pigeon
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
