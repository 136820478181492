import React, { useEffect, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Loading from '../components/common/Loading'
import Sidebar from '../components/common/Sidebar/Sidebar'
import Navbar from '../components/common/Navbar'
import Footer from '../components/common/Footer'
import Page404 from '../pages/auth/Page404'
import { useDispatch, useSelector } from 'react-redux'
import { components, routes } from './utils/consts'
import { temporalAllowOnBoard } from './utils/temporalAllowOnBoard'
import { hideSidebar, showSidebar } from '../redux/actions/settings'

const LoginRoutes = () => {
  const dispatch = useDispatch()

  const { activeUser } = useSelector((state) => state.auth)
  const { isOpen } = useSelector((state) => state.settings)

  useEffect(() => {
    if (window.innerWidth <= 768 && !isOpen) {
      dispatch(hideSidebar())
    } else {
      dispatch(showSidebar())
    }
  }, [])

  const isSmallAndOpen = window.innerWidth <= 768 && isOpen

  const userRole = activeUser.is_substitute
    ? 'substitute'
    : activeUser.is_procurador
    ? 'procurador'
    : activeUser.role

  const combinedRoutes = [...(routes[userRole] || []), ...routes.common].concat(
    temporalAllowOnBoard(activeUser)
  )

  return (
    <>
      <div className="wrapper">
        <Sidebar isSmallAndOpen={isSmallAndOpen} />
        <div className="main">
          <Navbar />
          <Suspense fallback={<Loading />}>
            <div className="layout-content">
              <Routes>
                {combinedRoutes.map((route, index) =>
                  route.redirect ? (
                    <Route
                      key={index}
                      path={route.path}
                      element={<Navigate to={route.to} replace />}
                    />
                  ) : (
                    <Route
                      key={index}
                      path={route.path}
                      element={React.createElement(components[route.component])}
                    />
                  )
                )}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </div>
          </Suspense>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default LoginRoutes
