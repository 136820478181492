export const LABELS = {
  dashboard: 'Dashboard',
  pigeonAI: 'Pigeon AI - Beta',
  users: 'Usuarios',
  inbox: 'Bandeja de entrada',
  shoppingCart: 'Compras',
  expedients: 'Encargos Contratados',
  assigned: 'Encargos Asignados',
  hired: 'Encargos Contratados',
  views: 'Vistas Abogado',
  hearings: 'Encargos',
  notary: 'Notaría',
  onCall: 'OnCall',
  onBoard: 'Pigeon On Board',
  procura: 'Vistas Procura',
  drafts: 'Borradores',
  judicialBriefs: 'Escritos Judiciales',
  escritos: 'Escritos',
  invoices: 'Facturas',
  hearingInvoices: 'Facturas Encargos',
  substitutionInvoices: 'Facturas Sustituciones',
  incomeCompany: 'Company',
  incomeClients: 'Clientes',
  expenseSubstitutes: 'Sustitutos',
  remittances: 'Remesas',
  tools: 'Herramientas',
  calculator: 'Calculadora intereses',
  partners: 'Partners',
  promotions: 'Promociones',
  dataset: 'Dataset',
  budget: 'Presupuestos',
  notifications: 'Notificaciones',
  refund: 'Devoluciones',
}
